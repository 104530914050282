import { useMemo, memo, useEffect } from 'react';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';

import { Collectible } from '../../../sci-ui-components/types/collectible';
import { ColorsMap } from '../../../sci-ui-components/utils/colors';
import { StatType } from '../../../sci-ui-components/types/statType';
import DateRangeSelector, {
  DateRangeSelectorProps,
} from '../../../sci-ui-components/DateRangeSelector/DateRangeSelector';
import Tabs from '../../../sci-ui-components/Tabs/Tabs';
import DaysFilter from '../../../sci-ui-components/forms/DaysFilter/DaysFilter';
import { FullScreenContainer } from '../../../sci-ui-components/FullScreen/index';
import { ChartData, CollectiblesChartStats } from '../../../sci-ui-components/types/chartData';
import useAnalytics from '../../analytics/useAnalytics';
import useChartFullScreenState from '../useChartFullScreenState';
import ChartSettingsDropdown from '../ChartSettingsDropdown/ChartSettingsDropdown';
import useChartSettings from '../useChartSettings';
import useLayoutControlState from '../../app/shells/MembersApp/useLayoutControlState';
import useSelectedCollectibleIds from '../../search/useSelectedCollectibleIds';
import LineChart, { ChartValueField as LineChartValueField } from './LineChart';
import BarChart, { ChartValueField as BarChartValueField } from './BarChart';
import { groupChartData, SpecificPoint } from './utils';
import ChartsLegend from './ChartsLegend';
import classes from './Charts.module.scss';
import useToday from 'sci-ui-components/hooks/useToday';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';
import { getLimitForMembershipTierPermissionCategory } from 'features/user/utils';
import { showError } from 'services/toaster';
import useUser from 'features/user/useUser';
import { CollectibleType, isSealedWaxCardCollectibleType } from 'sci-ui-components/types/collectibleType';
import { Breakpoint } from 'sci-ui-components/types/breakpoints';
import { useBreakpoint } from 'sci-ui-components/hooks/useBreakpoint';
import {
  getNumberOfDaysInDateRange,
  IsoDateRange,
  getDaysOptionFromDateRange,
  DateRangeDaysCount,
  getEarliestStartDate,
} from 'sci-ui-components/utils/date';

type TabKey =
  | 'price-change-amount'
  | 'price-change-percentage'
  | 'total-sales-amount'
  | 'number-of-sales'
  | 'last-avg-sale';
const defaultTab: TabKey = 'price-change-amount';
const emptyMessage = 'No data found in this time frame - please adjust your date range.';

export default memo(function Charts({
  className,
  collectibleType,
  data,
  specificPoints,
  collectibles,
  collectibleIds,
  dateRange,
  chartedDateRange,
  onDateRangeChange,
  highlightedCollectibleId,
  colors,
  isLoading,
  collectibleStats,
  hiddenCollectiblesIds = [],
  onToggleCollectibleVisibility,
  onCollectibleHover,
  alwaysShowLegend = false,
  noFullScreen = false,
  tabsClassName,
  chartHeight,
  onIsFullScreenChange,
  dateRangeFormatStr,
  dateRangeLabel,
  dateSelectorContentClassName,
  dayFilterButtonClassName,
  periodFiltersDropdownOnBreakpoints,
  daysFilterDropdownOnBreakpoints,
  tabsDropdownOnBreakpoints,
}: {
  className?: string;
  collectibleType: CollectibleType;
  data: ChartData[];
  specificPoints?: SpecificPoint[];
  collectibles: Collectible[];
  collectibleIds?: number[];
  dateRange: IsoDateRange;
  chartedDateRange: IsoDateRange | null;
  onDateRangeChange: (dateRange: IsoDateRange) => void;
  highlightedCollectibleId?: number | null;
  colors: ColorsMap<number>;
  isLoading: boolean;
  collectibleStats: CollectiblesChartStats | null;
  hiddenCollectiblesIds?: number[];
  onToggleCollectibleVisibility?: (collectibleId: number) => void;
  onCollectibleHover?: (collectibleId: number | null) => void;
  alwaysShowLegend?: boolean;
  noFullScreen?: boolean;
  tabsClassName?: string;
  chartHeight?: number;
  onIsFullScreenChange?: (isFullScreen: boolean) => void;
  dayFilterButtonClassName?: string;
  periodFiltersDropdownOnBreakpoints?: Breakpoint[];
  daysFilterDropdownOnBreakpoints?: Breakpoint[];
  tabsDropdownOnBreakpoints?: Breakpoint[];
} & Pick<DateRangeSelectorProps, 'dateRangeFormatStr' | 'dateRangeLabel' | 'dateSelectorContentClassName'>) {
  const { selectedCurrencySymbol } = useCurrencyFormatter();
  const { trackEvent } = useAnalytics();
  const { data: user } = useUser();
  const { setIsHeaderVisible } = useLayoutControlState();
  const breakpoint = useBreakpoint(true);
  const [isFullScreen, toggleFullScreen] = useChartFullScreenState(noFullScreen);
  const { chartSettings, setGroupBy, setTrend, setYBaseline } = useChartSettings({ dateRange: chartedDateRange });
  const dataByDate = useMemo(
    () => groupChartData(data, specificPoints, chartSettings.groupBy),
    [data, specificPoints, chartSettings.groupBy]
  );
  const { replaceCollectibleId } = useSelectedCollectibleIds();
  const today = useToday();
  const allDays = useMemo(() => differenceInDays(today, getEarliestStartDate(today)), [today]);
  const days = getNumberOfDaysInDateRange(dateRange);
  const dateRangeDays: DateRangeDaysCount = getDaysOptionFromDateRange(dateRange) ?? (days === allDays ? 'all' : days);

  useEffect(() => {
    onIsFullScreenChange?.(isFullScreen);
    setIsHeaderVisible(!isFullScreen);
  }, [isFullScreen, onIsFullScreenChange, setIsHeaderVisible]);

  const _onDateRangeChange = (dateRange: IsoDateRange) => {
    if (
      isSealedWaxCardCollectibleType(collectibleType) &&
      getNumberOfDaysInDateRange(dateRange) > getLimitForMembershipTierPermissionCategory(user, 'waxChartsDateRange')
    ) {
      showError({
        description: (
          <div>
            Your plan includes data from the last 30 days only.
            <a href="https://www.sportscardinvestor.com/account/?action=subscriptions" target="_blank" rel="noreferrer">
              Upgrade your plan to access additional time periods.
            </a>
          </div>
        ),
      });
    } else {
      onDateRangeChange(dateRange);
    }
  };

  const header = (
    <div
      className={clsx(classes.chartHeader, {
        [classes.chartHeaderColumn]:
          breakpoint && periodFiltersDropdownOnBreakpoints?.some((item) => item === breakpoint),
      })}
    >
      <DateRangeSelector
        dateRangeFormatStr={dateRangeFormatStr}
        dateRangeLabel={dateRangeLabel}
        dateSelectorContentClassName={dateSelectorContentClassName}
        onChange={_onDateRangeChange}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
      />
      <DaysFilter
        fieldLabel="show"
        dropdownOnBreakpoints={daysFilterDropdownOnBreakpoints}
        onDateChange={_onDateRangeChange}
        dateRange={dateRange}
        dayFilterButtonClassName={dayFilterButtonClassName}
        notEmpty
      />
    </div>
  );
  const chartSettingsControls = (
    <ChartSettingsDropdown
      chartSettings={chartSettings}
      setGroupBy={setGroupBy}
      setTrend={setTrend}
      setYBaseline={setYBaseline}
    />
  );

  const renderLineChart = (
    chartValueField: LineChartValueField,
    chartValueType: StatType,
    chartValueTrendField?: LineChartValueField,
    specificPoints?: SpecificPoint[]
  ) => (
    <LineChart
      header={header}
      chartSettingsControls={chartSettingsControls}
      chartSettings={chartSettings}
      data={dataByDate}
      colors={colors}
      chartValueField={chartValueField}
      chartValueTrendField={chartValueTrendField}
      chartValueType={chartValueType}
      collectibles={collectibles}
      highlightedCollectibleId={highlightedCollectibleId}
      isLoading={isLoading}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      emptyMessage={emptyMessage}
      specificPoints={specificPoints}
      height={chartHeight}
      headerSectionClassName={classes.chartsHeaderSection}
      chartsWrapperClassName={classes.chartsWrapperRoot}
    />
  );
  const renderBarChart = (chartValueField: BarChartValueField, chartValueType: StatType) => (
    <BarChart
      header={header}
      colors={colors}
      chartValueField={chartValueField}
      chartValueType={chartValueType}
      collectibles={collectibles}
      hiddenCollectiblesIds={hiddenCollectiblesIds}
      highlightedCollectibleId={highlightedCollectibleId}
      isLoading={isLoading}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      collectibleStats={collectibleStats}
      emptyMessage={emptyMessage}
      height={chartHeight}
      headerSectionClassName={classes.chartsHeaderSection}
      chartsWrapperClassName={classes.chartsWrapperRoot}
    />
  );

  return (
    <FullScreenContainer
      className={className}
      fullScreenClassName={classes.fullscreen}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
    >
      <Tabs<TabKey>
        className={clsx(classes.tabs, tabsClassName)}
        dropdownOnBreakpoints={tabsDropdownOnBreakpoints}
        defaultTabId={defaultTab}
        variant="secondary"
        onTabClick={(tabId) => {
          trackEvent({
            eventName: 'CHARTS_TAB_SELECTED',
            tab: tabId,
            collectibleType,
          });
        }}
        tabs={[
          {
            id: 'price-change-amount',
            title: `Price Changes - ${selectedCurrencySymbol}`,
            content: renderLineChart('avgSalePrice', 'price', 'avgSalePriceTrend', specificPoints),
          },
          {
            id: 'price-change-percentage',
            title: 'Price Changes - %',
            content: renderLineChart('avgSalePriceChangePercentage', 'percentage'),
          },
          {
            id: 'total-sales-amount',
            title: `Total Sales - ${selectedCurrencySymbol}`,
            content: renderLineChart('totalAmount', 'price'),
          },
          {
            id: 'number-of-sales',
            title: 'Number of Sales',
            content: renderBarChart('salesCount', 'count'),
          },
          {
            id: 'last-avg-sale',
            title: 'Last Sale',
            content: renderBarChart('endAvgSalePrice', 'price'),
          },
        ]}
      />
      {(isFullScreen || alwaysShowLegend) && (
        <ChartsLegend
          className={classes.legend}
          collectibleIds={collectibleIds ?? []}
          collectibleStats={collectibleStats}
          collectibleType={collectibleType}
          colors={colors}
          collectibles={collectibles}
          hiddenCollectiblesIds={hiddenCollectiblesIds}
          onToggleCollectibleVisibility={onToggleCollectibleVisibility}
          isLoading={isLoading}
          onCollectibleHover={onCollectibleHover}
          chartedDays={dateRangeDays}
          onReplaceCollectible={replaceCollectibleId}
        />
      )}
    </FullScreenContainer>
  );
});
